import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@smooth-ui/core-sc'

import { TextInput } from '../TextInput/TextInput'
import { Button } from '../Button/Button'

const LOGIN_STATUS = {
  SUCCESS: 'success',
}

export const LoginForm = (props) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const doLogin = (e) => {
    e.preventDefault()
    setLoading(true)

    fetch(`${process.env.GATSBY_SPORTME}/v2/users/sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email,
          password,
        }
      })
    }).then(res => res.json())
    .then((res) => {
      if (res.status === LOGIN_STATUS.SUCCESS) {
        props.onSuccess(res.data)
      } else {
        // display error in the frontend
      }
      setLoading(false)
    })
    .catch(() => setLoading(false))
  }

  return (
    <form onSubmit={doLogin}>
      <Box mb="20px">
        <TextInput
          type="email"
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
          aria-label='email input'
          disabled={loading} />
      </Box>
      <Box mb="20px">
        <TextInput
          type="password"
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
          aria-label='password input'
          disabled={loading} />
      </Box>
      <Button
        full
        aria-label='send button'
        disabled={loading}>Send</Button>
    </form>
  )
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func,
}

LoginForm.defaultProps = {
  onSuccess: () => {},
}