import styled from 'styled-components'
import { Grid, up, css, th } from '@smooth-ui/core-sc'

export const StyledHeader = styled.header`
  height: 54px;
  z-index: 3;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
`

export const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${up('lg', css`

    justify-content: space-between;

  `)}
`

export const StyledLinks = styled.div`
  position: fixed;
  flex-direction: column;
  left: 0;
  top: 53px;
  right: 0;
  bottom: 0;
  background: ${th('white')};
  text-align: center;

  display: ${props => props.menuOpen ? 'flex' : 'none'};

  > a {
    text-transform: uppercase;
    font-size: 18px;
    color: rgba(26, 26, 26, 0.4);
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid ${th('gray100')};

    :hover {
      color: rgba(26, 26, 26, 0.8);
    }
  }

  ${up('lg', css`

    position: initial;
    display: flex;
    flex-direction: row;

    > a {
      border-bottom: 0;
      font-size: 16px;
      padding: 0 10px;
    }

  `)}
  
  ${up('xl', css`
     > a {
      font-size: 18px;
    }
  `)}
  
  
`
export const ScrollLink = styled.a`
  && {
    ${props => props.selected && `color: #F06449`}
  }
`

export const ToggleButton = styled.div`
  width: 45px;
  padding: 15px 10px 10px 15px;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: ' ';
    display: block;
    height: 2px;
    background: #222;
    margin-top: 5px;
  }
  &:after {
    content: ' ';
    display: block;
    height: 2px;
    background: #222;
    margin-top: 5px;
  }
`