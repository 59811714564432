import styled from 'styled-components'
import { th } from '@smooth-ui/core-sc'

export const StyledContainer = styled.span`
  position: relative;
  display: inline-block;

  &:after {
    content: 'Required';
    position: absolute;
    right: 20px;
    top: 2px;
    font-size: 14px;
    line-height: 42px;
    display: ${props => props.error ? 'block' : 'none'};
    opacity: ${props => props.error ? 1 : 0.3};
    color: ${props => props.error && th('primary')};
  }
  input::-webkit-inner-spin-button, 
  input::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`

export const StyledInput = styled.input`
  border: 2px solid ${th('gray100')};
  display: inline-block;
  width: ${props => props.expand && '100%'};
  border-radius: 100px;
  line-height: 42px;
  padding: 0 24px;
  border-color: ${props => props.error && th('primary')};
  background: transparent;

  &:focus {
    border-color: ${props => props.error ? th('primary') : th('gray300')};
    // commented because inputs do not work in safari otherwise
    // user-select: none;
    outline: none;
  }
`