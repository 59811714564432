import React from 'react'
import styled from 'styled-components'
import { Grid, Row, th, up, css } from '@smooth-ui/core-sc'
import facebookSvg from '../../images/Facebook.svg';
import twitterSvg from '../../images/Twitter.svg';
import instagramSvg from '../../images/Instagram.svg';

export const Footer = () => {

  return (
    <StyledFooter>
      <Grid>
        <Row>
          <TextContainer>
            <CopyrightAndHome>
              <span>Copyright © 2019</span>
              <SpacingMobile/>
              <a href='https://www.sfappworks.com/' aria-label='link to SF AppWorks Site'
                 target='_blank' rel='noreferrer'
                 style={{ fontWeight: '700' }}>SF AppWorks</a>
              </CopyrightAndHome>
            <TermsAndPolicy>
              <HideMobileSpan>|</HideMobileSpan>
              <a href='https://www.sportme.com/terms-of-service' aria-label='link to terms of service page'
                    target='_blank' rel='noreferrer'>Terms of service</a>
              <Separator>|</Separator>
              <a href='https://www.sportme.com/sportme-privacy-policy' aria-label='link to privacy and policy page'
                    target='_blank' rel='noreferrer'>Privacy Policy</a>
            </TermsAndPolicy>
          </TextContainer>
        </Row>
        <SocialFooterContainer>
          <LinkContainer>
            <a href='https://twitter.com/SportMeInc' target='_blank' rel='noreferrer'
              aria-label='link to sportMe on twitter'>
              <Circle>
                <img src={twitterSvg} />
              </Circle>
            </a>
          </LinkContainer>
          <LinkContainer>
            <a href='https://www.facebook.com/SportMeInc/' target='_blank' rel='noreferrer'
              aria-label='link to sportMe on facebook'>
              <Circle>
                <img src={facebookSvg} />
              </Circle>
            </a>
          </LinkContainer>
          <LinkContainer>
            <a href='https://www.instagram.com/sportmerun/' target='_blank' rel='noreferrer'
               aria-label='link to sportMe on instagram'>
              <Circle>
                <img src={instagramSvg} />
              </Circle>
            </a>
          </LinkContainer>
        </SocialFooterContainer>
      </Grid>
    </StyledFooter>
  )

}

const StyledFooter = styled.footer`
  background: ${th('gray900')};
  color: ${th('white600')};
  font-size: 14px;
  height: 108px;
  padding: 11px 0 17px 0;

  a {
    color: ${th('white600')};
  }
  ${up('lg', css`
    height: 80px;
    padding: 29.5px 0;
    position: relative;
  `)}
`

const CopyrightAndHome = styled.div`
  width: 194px;
  ${up('lg', css`
    width: auto;
  `)}
  a:hover, a:active, a:focus {
    color: #fff;
  }
`

const SocialFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 9px;
  ${up('lg', css`
    position: absolute;
    margin-top: 0;
    right: 60px;
    top: 20px;
  `)}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${up('lg', css`
    flex-direction: row;
  `)}
`

const LinkContainer = styled.div`
  margin: 0 10px;
  height: 32px;
  a {
    height: 32px;
  }
  ${up('lg', css`
    cursor: pointer;
    height: 40px;
    margin: 0 5px;
    a {
      display: flex;
      height: 40px;
      width: 40px;
      img {
        width: 40px;
      }
    }
  `)}
`

const SpacingMobile = styled.span`
  display: inline-flex;
  width: 12px;
  ${up('lg', css`
    width: 4px;
  `)}
`

const TermsAndPolicy = styled.div`
  font-size: 12px;
  a:hover, a:active, a:focus {
    color: #fff;
  }
  ${up('lg', css`
    font-size: 14px;
  `)}
`

const HideMobileSpan = styled.span`
  display: none;

  ${up('lg', css`
    display: inline;
    margin: 0 4px;
  `)}
`
const Separator = styled.span`
  margin: 0 12px;

  ${up('lg', css`
    margin: 0 4px;
  `)}
`
const Circle = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 50%;
  display: flex;  
  justify-content: center;
  height: 32px;
  opacity: 0.6;
  width: 32px;
  
  ${up('lg', css`
    height: 40px;
    width: 40px;
    &:hover {
      opacity: 1;
    }
  `)}
`;
