import React from 'react'

import { StyledInput, StyledContainer } from './styles'

export const TextInput = props => {
  return (
    <StyledContainer {...props}>
      <StyledInput {...props} />
    </StyledContainer>
  )
}
