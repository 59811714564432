import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { up, css } from '@smooth-ui/core-sc'

const BannerContainer = styled.div`
    align-items: center;
    background: yellow;
    color: #000;
    display: flex;
    font-size: 14px;
    padding: 10px 20px;
    position: absolute;
    top: 54px;  
    width: 100%; 
    
    a {
        color: red;
        margin-left: 2px;
    }
    
    button {
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 30px;
        height: 30px;
        margin: 0 -10px 0 auto;
        outline: none;
        width: 30px;
    }
    
    ${up('lg', css`
        font-size: 16px;
        padding: 10px 40px;
  `)}
`

export const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false)
    const localStorageGlobal = typeof localStorage !== 'undefined' && localStorage;
    const isBannerSeen = localStorageGlobal && localStorage.getItem('userCookieBanner')

    useEffect(() => {
        isBannerSeen ? setShowBanner(false) : setShowBanner(true)
    })

    return (
        <>
        { showBanner &&
            <BannerContainer>
              <span>We use cookies to improve your experience on our website. By continuing to use this website, you agree
to the use of cookies. To learn more about how we use cookies, please see our
                <a href='https://www.sportme.com/sportme-privacy-policy'
                   onClick={() => localStorage.setItem('userCookieBanner', true)}
                   aria-label='link to privacy and policy page'
                   target='_blank' rel='noreferrer'> Cookie Policy</a>
                  .
              </span>
              <button onClick={() => {
                    setShowBanner(false)
                    localStorage.setItem('userCookieBanner', true)
                }}>
                  ×
              </button>
            </BannerContainer>
        }
        </>

    )
}