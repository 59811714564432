import React from 'react'
import { Normalize, Box } from "@smooth-ui/core-sc"

import { SiteMetadata } from '../components/SiteMetadata/SiteMetadata'
import { GlobalStyle } from "../style/theme"
import { Footer } from '../components/Footer/Footer'

const footerHeight = 85
const headerHeight = 54

export const MainLayout = ({ pathname = '/', children }) => {
  return (
    <div css={`padding-top: 54px;`}>
      <SiteMetadata pathname={pathname} />
      <Normalize />
      <GlobalStyle />
      <div css={`min-height: calc(100vh - ${(footerHeight + headerHeight)}px);`}>
        {children}
      </div>
      <Footer />
    </div>
  )
}