import styled from 'styled-components'
import { Link } from 'gatsby'
import { th } from '@smooth-ui/core-sc'

export const StyledLink = styled.a`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  border-radius: 7px;
  background-color: ${props => props.dark ? th('white800') : th('gray800')};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.dark ? th('white') : th('gray')}
  }

  > img {
    display: block
  }
`

export const StyledButton = styled.button`
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  color: ${th('white')};
  display: inline-block;
  line-height: 60px;
  width: ${props => props.expand ? '100%' : '405px'};
  max-width: 100%;
  border-radius: 30px;
  background-color: ${props => props.disabled ? th('white600') : th('primary')};
  text-align: center;
  border: none;
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};

  &:hover {
    background-color: ${props => !props.disabled && th('primaryHover')};
    color: ${th('white')};
  }

  &:focus {
    outline: none;
  }
`

export const StyledGatsbyLink = styled(Link)`
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  color: ${th('white')};
  display: inline-block;
  line-height: 60px;
  width: 405px;
  max-width: 100%;
  border-radius: 30px;
  background-color: ${props => th('primary')};
  text-align: center;

  &:hover {
    background-color: ${th('primaryHover')};
    color: ${th('white')};
  }

  &:focus {
    outline: none;
  }
`