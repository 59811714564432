import React from 'react'

import { StyledButton, StyledGatsbyLink } from './styles'

export const Button = ({ link, ...rest }) => {
  return link
    ? (
      <StyledGatsbyLink to={link} {...rest} />
    )
    : (
      <StyledButton {...rest} />
    )
}